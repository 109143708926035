import { v4 as uuidv4 } from "uuid";

const ChartOfAccounts = [
 {
   "id": uuidv4(),
   "AccountName": "Cash - Operating Account"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cash - Payroll Account"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cash - Money Market Account"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cash - User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cash - User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cash - User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cash - Petty Cash"
 },
 {
   "id": uuidv4(),
   "AccountName": "Marketable Securities"
 },
 {
   "id": uuidv4(),
   "AccountName": "User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "AR - Trade"
 },
 {
   "id": uuidv4(),
   "AccountName": "AR - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "AR - User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "AR - User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "Allowance for Doubtful Accounts"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unbilled - Accounts Receivable"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unbilled - Retainage"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unbilled - Liquidated Balance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Travel Advances"
 },
 {
   "id": uuidv4(),
   "AccountName": "Advances to Employees"
 },
 {
   "id": uuidv4(),
   "AccountName": "Advances to Officers"
 },
 {
   "id": uuidv4(),
   "AccountName": "Prepaid Insurance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Prepaid Rent"
 },
 {
   "id": uuidv4(),
   "AccountName": "Prepaid Software Support"
 },
 {
   "id": uuidv4(),
   "AccountName": "Other Prepaid Expenses"
 },
 {
   "id": uuidv4(),
   "AccountName": "Inventory - Raw Materials"
 },
 {
   "id": uuidv4(),
   "AccountName": "Inventory - Obsolete and Excess Inv Reserve"
 },
 {
   "id": uuidv4(),
   "AccountName": "Inventory - Finished Goods"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP DL - ENG"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP DL - MFG"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP DL - CS"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Materials"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Other Direct Costs"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Burden"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Burden"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Burden"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Reserve"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP Transfer Out"
 },
 {
   "id": uuidv4(),
   "AccountName": "WIP User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "Furniture & Fixtures"
 },
 {
   "id": uuidv4(),
   "AccountName": "Equipment(Non-Computer)"
 },
 {
   "id": uuidv4(),
   "AccountName": "Computer Equipment"
 },
 {
   "id": uuidv4(),
   "AccountName": "Automobiles"
 },
 {
   "id": uuidv4(),
   "AccountName": "Software"
 },
 {
   "id": uuidv4(),
   "AccountName": "Leasehold Improvements"
 },
 {
   "id": uuidv4(),
   "AccountName": "Furniture & Fixtures"
 },
 {
   "id": uuidv4(),
   "AccountName": "Equipment(Non-Computer)"
 },
 {
   "id": uuidv4(),
   "AccountName": "Computer Equipment"
 },
 {
   "id": uuidv4(),
   "AccountName": "Automobiles"
 },
 {
   "id": uuidv4(),
   "AccountName": "Software"
 },
 {
   "id": uuidv4(),
   "AccountName": "Leasehold Improvements"
 },
 {
   "id": uuidv4(),
   "AccountName": "Security Deposits"
 },
 {
   "id": uuidv4(),
   "AccountName": "Organization Costs"
 },
 {
   "id": uuidv4(),
   "AccountName": "Trade Accounts Payable"
 },
 {
   "id": uuidv4(),
   "AccountName": "Advance Billings"
 },
 {
   "id": uuidv4(),
   "AccountName": "Federal W/H Tax"
 },
 {
   "id": uuidv4(),
   "AccountName": "FICA W/H Tax"
 },
 {
   "id": uuidv4(),
   "AccountName": "FUI"
 },
 {
   "id": uuidv4(),
   "AccountName": "FUTA"
 },
 {
   "id": uuidv4(),
   "AccountName": "State 1 W/H Tax"
 },
 {
   "id": uuidv4(),
   "AccountName": "State 2 W/H Tax"
 },
 {
   "id": uuidv4(),
   "AccountName": "State SUI Tax W/H"
 },
 {
   "id": uuidv4(),
   "AccountName": "City Tax W/H"
 },
 {
   "id": uuidv4(),
   "AccountName": "Retirement"
 },
 {
   "id": uuidv4(),
   "AccountName": "401k Plan contribution"
 },
 {
   "id": uuidv4(),
   "AccountName": "401k Additional"
 },
 {
   "id": uuidv4(),
   "AccountName": "Health Insurance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Dental Insurance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Child Support"
 },
 {
   "id": uuidv4(),
   "AccountName": "Granishments"
 },
 {
   "id": uuidv4(),
   "AccountName": "Deferred Revenue"
 },
 {
   "id": uuidv4(),
   "AccountName": "Accrued Vacation"
 },
 {
   "id": uuidv4(),
   "AccountName": "Accrued Insurance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Professional Fees"
 },
 {
   "id": uuidv4(),
   "AccountName": "Accrued Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "Accrued Payroll (Regular)"
 },
 {
   "id": uuidv4(),
   "AccountName": "Accrued Bonuses"
 },
 {
   "id": uuidv4(),
   "AccountName": "Federal Income Taxes Payable"
 },
 {
   "id": uuidv4(),
   "AccountName": "Deferred Income Taxes"
 },
 {
   "id": uuidv4(),
   "AccountName": "State Corporate Business Taxes Payable"
 },
 {
   "id": uuidv4(),
   "AccountName": "Common Stock"
 },
 {
   "id": uuidv4(),
   "AccountName": "Preferred Stock"
 },
 {
   "id": uuidv4(),
   "AccountName": "Shareholders' Equity"
 },
 {
   "id": uuidv4(),
   "AccountName": "Paid In Capital"
 },
 {
   "id": uuidv4(),
   "AccountName": "Retained Earnings - Current Year"
 },
 {
   "id": uuidv4(),
   "AccountName": "Retained Earnings - Prior Years"
 },
 {
   "id": uuidv4(),
   "AccountName": "Revenue - Government"
 },
 {
   "id": uuidv4(),
   "AccountName": "Revenue - Commercial"
 },
 {
   "id": uuidv4(),
   "AccountName": "Revenue - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "Revenue - Intercompany"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Salaries - Eng"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Salaries - Mfg"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Salaries - CS"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Consultants Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Consultants Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Sub Contract Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Sub Contract Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Temp Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Temp Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Payroll Taxes - FICA"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Payroll Taxes - FUTA"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Payroll Taxes - SUI/SDI"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Group Insurance - Medical"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL  Group Insurance - Dental"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL  Group Insurance - Life"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL  Group Insurance - Vision"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL  Insurance - Auto - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Insurance - Commercial - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL  Insurance - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Retirement - Profit Sharing"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Retirement - 401K"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Prof. Dev. - Continuing Education"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Prof. Dev. - Training"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Prof. Dev. - Tuition Reimbursement"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Holiday Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Sick Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Vacation Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Jury Duty"
 },
 {
   "id": uuidv4(),
   "AccountName": "DL Car Allowance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Materials - Manufacturing"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Materials - Raw Materials"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Materials - Finished Goods"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Materials - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "Adjustment to Cost"
 },
 {
   "id": uuidv4(),
   "AccountName": "Shrinkage"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cost Variance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "COGS"
 },
 {
   "id": uuidv4(),
   "AccountName": "COGS"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cost of Goods Sold"
 },
 {
   "id": uuidv4(),
   "AccountName": "Cost of Goods Sold - Misc"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Airfare"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Project Travel - Lodging"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Car Rental"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Fuel"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Per Diem"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Meals"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Phone"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Taxi & Transportation"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Tolls"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Travel - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Equip. Rental/Lease - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Equip. Maintenance - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Equipment & Fixture Expenditures - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Paper and Supplies - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Publications - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Uniforms - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Postage - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Delivery - Direct"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Telephone"
 },
 {
   "id": uuidv4(),
   "AccountName": "Direct Cost Cellular Telephone"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Salaries - Bonus"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Consultant Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Consultant Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Consultant Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Consultant Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Sub Contract Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Sub Contract Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Sub Contract Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Temp Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Temp Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Temp Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Airfare"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Lodging"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Car Rental"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Fuel"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Per Diem"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Meals"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Phone"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Taxi & Transportation"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Tolls"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Travel - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Supplies and Equipment"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Software Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Hardware Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Facility Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Copier Rental/Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Postage Mach. Rental/Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Fax Machine Rental/Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Paper and Supplies"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Publications - Overhead"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Postage"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Delivery"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Telephone"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Cellular Telephone"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Facility - Rent"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Facility - Utilities, Electricity"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Facility - Utilities, Water & Sewer"
 },
 {
   "id": uuidv4(),
   "AccountName": "OH Facility - Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Salaries - Bonus"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Consultant Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Consultant Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Salaries - Overtime"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Payroll Taxes - FICA"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Payroll Taxes - FUTA"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Payroll Taxes - SUI/SDI"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Group Insurance - Medical"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Group Insurance - Dental"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Group Insurance - Life"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Group Insurance - Vision"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Insurance - Auto"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Insurance - Commercial"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Retirement - Profit Sharing"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Retirement - 401K"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Prof. Dev. - Continuing Education"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Prof. Dev. - Training"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Prof. Dev. - Tuition Reimbursement"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Holiday Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Sick Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Vacation Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Jury Duty"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Car Allowance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Airfare"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Lodging"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Car Rental"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Fuel"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Per Diem"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Meals"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Phone"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Taxi & Transportation"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Tolls"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Travel - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Supplies and Equipment"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Software Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Hardware Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Facility Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Copier Rental/Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Postage Mach. Rental/Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Fax Machine Rental/Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Office Supplies"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Publications"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Postage"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Delivery"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Telephone"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Cellular Telephone"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Facility - Rent"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Facility - Utilities, Electricity"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Facility - Utilities, Water & Sewer"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Facility - Maintenance"
 },
 {
   "id": uuidv4(),
   "AccountName": "Professional Fees - Accounting & Audit"
 },
 {
   "id": uuidv4(),
   "AccountName": "Professional Fees - Consulting"
 },
 {
   "id": uuidv4(),
   "AccountName": "Professional Fees - Legal Fees"
 },
 {
   "id": uuidv4(),
   "AccountName": "Professional Fees - IT Support & Services"
 },
 {
   "id": uuidv4(),
   "AccountName": "Classified Ads"
 },
 {
   "id": uuidv4(),
   "AccountName": "Employment Agency Fees"
 },
 {
   "id": uuidv4(),
   "AccountName": "Employment Job Fair"
 },
 {
   "id": uuidv4(),
   "AccountName": "Contributions"
 },
 {
   "id": uuidv4(),
   "AccountName": "Bank Charges"
 },
 {
   "id": uuidv4(),
   "AccountName": "Management Fees"
 },
 {
   "id": uuidv4(),
   "AccountName": "Bad Debt Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "Purchase Discounts"
 },
 {
   "id": uuidv4(),
   "AccountName": "Dues and Subscriptions"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Amortization of Goodwill"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Depreciation Expense-Furniture & Fixtures"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Depreciation Expense-Equipment(Non-Computer)"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Depreciation Expense-Computer Equipment"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Depreciation Expense-Automobiles"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Depreciation Expense-Software"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A Depreciation Expense-Leasehold Improvements"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "G&A User Defined"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Salaries - Regular"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Salaries - Bonus"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Salaries - Others"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Payroll Taxes - FICA"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Payroll Taxes - FUTA"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Payroll Taxes - SUI/SDI"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Group Insurance - Medical"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Group Insurance - Dental"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Group Insurance - Life"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Group Insurance - Vision"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Airfare"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Lodging"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Car Rental"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Fuel"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Per Diem"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Meals"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Phone"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Taxi & Transportation"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Tolls"
 },
 {
   "id": uuidv4(),
   "AccountName": "B&P Travel - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "Advertising"
 },
 {
   "id": uuidv4(),
   "AccountName": "Publication"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Labor in Excess of Ceiling"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Labor to Correct Problems"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Airfare"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Lodging"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Car Rental"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Fuel"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Per Diem"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Meals"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Phone"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Taxi & Transportation"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Tolls"
 },
 {
   "id": uuidv4(),
   "AccountName": "Unallowable Travel - Other"
 },
 {
   "id": uuidv4(),
   "AccountName": "Interest Income"
 },
 {
   "id": uuidv4(),
   "AccountName": "Interest Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "Other Income"
 },
 {
   "id": uuidv4(),
   "AccountName": "Other Expense"
 },
 {
   "id": uuidv4(),
   "AccountName": "Dividend Income"
 },
 {
   "id": uuidv4(),
   "AccountName": "Fixed Asset Disposal Gain/Loss"
 },
 {
   "id": uuidv4(),
   "AccountName": "Federal Income Taxes"
 },
 {
   "id": uuidv4(),
   "AccountName": "State Franchise Tax"
 },
 {
   "id": uuidv4(),
   "AccountName": "State Excise Tax"
 },
 {
   "id": uuidv4(),
   "AccountName": "Deferred Tax Expense"
 }
]

export default ChartOfAccounts